<template>
  <div class="mainList">
    <!-- <el-row>
          <el-col :span="3" v-for="item in list" :key="item.id">
            {{item.content}}
          </el-col>
        </el-row> -->
    <el-row>
      <el-col :span="3"><router-link to="/personal/ziliao">自序</router-link></el-col>
      <el-col :span="3"><router-link to="/hobby">爱好</router-link></el-col>
      <el-col :span="3"><router-link to="/production">作品</router-link></el-col>
      <el-col :span="3"> <span @click="getSKL">自序</span> </el-col>
      <el-col :span="3"> <router-link to="/animation">animation</router-link> </el-col>
      <el-col :span="3"><router-link to="/study">学习</router-link></el-col>
    </el-row>
    <ul>
      <li>
        <el-tooltip class="item" effect="dark" content="gitee主页" placement="top-end">
          <a href="https://gitee.com/felikschen" target="_blank">
            <v-icon name="gitee"></v-icon>
          </a>
        </el-tooltip>
      </li>
      <li>
        <el-tooltip class="item" effect="light" content="CSDN主页" placement="top-end">
          <a href="https://blog.csdn.net/qq_47770336?spm=1010.2135.3001.5421">
            <v-icon name="csdn"></v-icon>
          </a>
        </el-tooltip>
      </li>
      <li>
        <el-tooltip class="item" effect="dark" content="邮箱联系felikschen@163.com" placement="top-end">
          <a href="mailto:felikschen@163.com">
            <v-icon name="email"></v-icon>
          </a>
        </el-tooltip>
      </li>
      <li>
        <el-tooltip class="item" effect="light" content="个人主页" placement="top-end">
          <a href="http://felikschen.xyz">
            <v-icon name="zhuye"></v-icon>
          </a>
        </el-tooltip>
      </li>
    </ul>

</div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'MainList',
  data() {
    return {
      list: [{ id: 1, content: '自序' },
      { id: 2, content: '收藏' },
      { id: 3, content: '爱好' },
      { id: 4, content: '成绩' },
      { id: 5, content: '测试' },
      { id: 6, content: '其他' },],
      data: {},
    }
  },
  methods: {
    getSKL() {
      let apiKey = '2WshDvM3a8682c1238af5a34f4eece5319e5a5637618bb7'
      let url = 'https://api.apishop.net/common/BMI/getStandardWeightTable?apiKey=' + apiKey;
      this.axios.get(url)
        .then(res => {
          console.log(res);
          this.data = res.data;
        })
    }
  },
}
</script>
<style scoped lang="less">
.el-row {
  width: 60%;
  margin: 100px auto;
  display: flex;
  justify-content: space-around;

  .el-col {
    border: 1px solid #fff;
    text-align: center;
    line-height: 40px;
    border-radius: 20px;
    color: #fff;

    &:hover {
      background-color: #b1d1f0;
      transition: .5s;
      cursor: pointer;
    }

    a {
      text-decoration: none;
      color: #fff;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

ul {
  list-style: none;
  width: 20%;
  margin: -50px auto;
  display: flex;
  justify-content: space-around;

  li {
    float: left;

    a {
      text-decoration: none;
      color: #fff;
      display: block;
      width: 100%;
      height: 100%;

      i {
        font-size: 30px;
        color: #212121;
        transition: all .5s linear;

        &:hover {
          color: #fff;
          transition: all .5s linear;
        }
      }
    }
  }
}
</style>
