<template>
  <div class="footer">
    <a href="https://beian.miit.gov.cn/#/Integrated/index">{{msg}}</a>
  </div>
</template>

<script>
export default {
  name: 'MainFooter',
  data() {
    return {
      msg: '渝ICP备2022006501号'
    }
  },
}
</script>

<style scoped>
a{
  text-decoration: none;
  font-size: 18px;
  color: #4e97d8;
}
</style>
