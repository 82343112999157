import Vue from 'vue'

//自定义拖拽指令，配合v-draggable使用
Vue.directive('draggable', {
  bind (el) {
    el.style.position = 'absolute';
    el.style.cursor = 'move';
    el.onmousedown = function(e){
      let disx = e.pageX - el.offsetLeft;
      let disy = e.pageY - el.offsetTop;
      document.onmousemove = function(e){
        el.style.left = e.pageX - disx + 'px';
        el.style.top = e.pageY - disy + 'px';
      }
    }
    document.onmouseup = function(){
      document.onmousemove = null;
    }
  }
})
