import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import PersonalInformation from "../views/self/PersonalInformation.vue";
import HobbyView from "../views/hobby/HobbyView.vue";
import Production from "../views/production/Production.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/main",
    name: "main",
    component: () => import("../views/MainView.vue"),
  },
  {
    path: "/personal",
    name: "personal",
    component: () => import("../views/self/PersonalInformation.vue"),
    children: [
      {
        //资料
        path: "ziliao",
        name: "ziliao",
        component: () => import("../views/self/InformationPart.vue"),
      },
      {
        //技能
        path: "skill",
        name: "skill",
        component: () => import("../views/self/SkillPart.vue"),
      },
      {
        //作品
        path: "production",
        name: "production",
        component: () => import("../views/self/ProductionPart.vue"),
      },
      {
        //经历
        path: "experience",
        name: "experience",
        component: () => import("../views/self/ExperiencePart.vue"),
      },
    ],
  },
  {
    //爱好
    path: "/hobby",
    name: "hobby",
    component: HobbyView,
  },
  {
    //作品
    path: "/production",
    name: "production",
    component: Production,
  },
  {
    path: "/animation",
    name: "animation",
    component: () => import("../views/animation/AnimationView.vue"),
  },
  {
    //demo
    path: "/beisaier",
    name: "beisaier",
    component: () => import("../views/production/Beisaier.vue"),
  },
  {
    path: "/waves",
    name: "waves",
    component: () => import("../views/animation/SimpleWaves.vue"),
  },
  {
    path: "/flex",
    name: "flex",
    component: () => import("../views/animation/FlexContainer.vue"),
  },
  {
    name: "loading",
    path: "/loading",
    component: () => import("../views/animation/LoadingView.vue"),
  },
  {
    name: "clock",
    path: "/clock",
    component: () => import("../views/animation/ClockDemo.vue"),
  },
  {
    name: "photo",
    path: "/photo",
    component: () => import("../views/animation/PhotoWall.vue"),
  },
  {
    name: "ikun",
    path: "/ikun",
    component: () => import("../views/animation/IkunView.vue"),
  },
  {
    name: "dog",
    path: "/dog",
    component: () => import("../views/animation/CuteDog.vue"),
  },
  {
    name: "coderain",
    path: "/coderain",
    component: () => import("../views/animation/CodeRain.vue"),
  },
  {
    name: "lovely",
    path: "/lovely",
    component: () => import("../views/animation/LovelyFont.vue"),
  },
  {
    name: "water",
    path: "/water",
    component: () => import("../views/animation/WaterDrop.vue"),
  },
  {
    name: "vmin",
    path: "/vmin",
    component: () => import("../views/animation/VminVmax.vue"),
  },
  {
    name: "grid",
    path: "/grid",
    component: () => import("../views/animation/GridAnimation.vue"),
  },
  {
    name: "firework",
    path: "/firework",
    component: () => import("../views/animation/FireWork.vue"),
  },
  {
    name: "spider",
    path: "/spider",
    component: () => import("../views/animation/SpiderRun.vue"),
  },
  //study
  {
    name: "study",
    path: "/study",
    component: () => import("../views/study/StudyIndex.vue"),
  },
  {
    name: "algorithm",
    path: "/algorithm",
    component: () => import("../views/study/algorithm/AlgorithmPart.vue"),
  },
  {
    name: "frame",
    path: "/frame",
    component: () => import("../views/study/frame/FramePart.vue"),
  },
  {
    name: "interview",
    path: "/interview",
    component: () => import("../views/study/interview/InterviewIndex.vue"),
  },
  {
    name: "h51",
    path: "/h51",
    component: () => import("../views/study/interview/h5/h51Index.vue"),
  },
];

const router = new VueRouter({
  mode: "hash", //打包前改成history
  base: process.env.BASE_URL,
  routes,
});

export default router;
