import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    username: 'FeliksChen',
    sex: '男',
    age: '20',
    count: 0,
    //学习页面数据--手风琴折叠面板数据结构
    h5:  [
      {
        id:1,
        title: '常用HTML标签',
        content: 'aaaaaaaaaaaaaaaaaaa',
        path: '/h51'
      },{
        id:2,
        title: 'HTML5新特性',
        content: 'bbbbbbbbbbbbbbbbbbb',
        path: '/h52'
      },{
        id:3,
        title: 'HTML5语义化标签',
        content: 'ccccccccccccccccccc',
        path: '/h53'
      },{
        id:4,
        title: 'iframe',
        content: 'ddddddddddddddddddd',
        path: '/h54'
      }],
    css:  [
      {
        id:'c1',
        title: '常用CSS样式',
        content: 'aaaaaaaaaaaaaaaaaaa'
      },{
        id:'c2',
        title: 'CSS3新特性',
        content: 'bbbbbbbbbbbbbbbbbbb'
      }
    ],
    js:  [
      {
        id:'j1',
        title: 'js中如何中止网络请求',
        content: '在 JavaScript 中，我们可以通过 AbortController 和 AbortSignal 来中⽌⽹络请求。使⽤ AbortController 创建⼀个新的 AbortController 对象，然后使⽤它的 abort() ⽅法来中⽌请求。在创建请求时，将 AbortController.signal 分配给请求的 signal 属性，以便在调⽤ abort() ⽅法时取消请求。',
        path: '/js1'
      },
      {
        id:'j2',
        title: '什么是 BOM 和 DOM？分别列举⼀些它们的函数',
        content: '在 JavaScript 中，我们可以通过 AbortController 和 AbortSignal 来中⽌⽹络请求。使⽤ AbortController 创建⼀个新的 AbortController 对象，然后使⽤它的 abort() ⽅法来中⽌请求。在创建请求时，将 AbortController.signal 分配给请求的 signal 属性，以便在调⽤ abort() ⽅法时取消请求。',
        path: '/js2'
      },
      {
        id:'j3',
        title: '深拷⻉和浅拷⻉有什么区别？JS 怎么实现深拷⻉？',
        content: '在 JavaScript 中，我们可以通过 AbortController 和 AbortSignal 来中⽌⽹络请求。使⽤ AbortController 创建⼀个新的 AbortController 对象，然后使⽤它的 abort() ⽅法来中⽌请求。在创建请求时，将 AbortController.signal 分配给请求的 signal 属性，以便在调⽤ abort() ⽅法时取消请求。',
        path: '/js3'
      },
      {
        id:'j4',
        title: '如何使⽤ JavaScript 来判断⽤户设备类型？⽐如判断是 PC 端还是移动端访问？',
        content: '在 JavaScript 中，我们可以通过 AbortController 和 AbortSignal 来中⽌⽹络请求。使⽤ AbortController 创建⼀个新的 AbortController 对象，然后使⽤它的 abort() ⽅法来中⽌请求。在创建请求时，将 AbortController.signal 分配给请求的 signal 属性，以便在调⽤ abort() ⽅法时取消请求。',
        path: '/js4'
      },
      {
        id:'j5',
        title: 'JS 中数组是如何在内存中存储的？',
        content: '在 JavaScript 中，我们可以通过 AbortController 和 AbortSignal 来中⽌⽹络请求。使⽤ AbortController 创建⼀个新的 AbortController 对象，然后使⽤它的 abort() ⽅法来中⽌请求。在创建请求时，将 AbortController.signal 分配给请求的 signal 属性，以便在调⽤ abort() ⽅法时取消请求。',
        path: '/js5'
      },
      {
        id:'j6',
        title: 'JS 中 Map 和 WeakMap 有什么区别？',
        content: '在 JavaScript 中，我们可以通过 AbortController 和 AbortSignal 来中⽌⽹络请求。使⽤ AbortController 创建⼀个新的 AbortController 对象，然后使⽤它的 abort() ⽅法来中⽌请求。在创建请求时，将 AbortController.signal 分配给请求的 signal 属性，以便在调⽤ abort() ⽅法时取消请求。',
        path: '/js6'
      },
      {
        id:'j7',
        title: '⽤ CSS 和 JS 来实现动画分别有哪些优缺点？',
        content: '在 JavaScript 中，我们可以通过 AbortController 和 AbortSignal 来中⽌⽹络请求。使⽤ AbortController 创建⼀个新的 AbortController 对象，然后使⽤它的 abort() ⽅法来中⽌请求。在创建请求时，将 AbortController.signal 分配给请求的 signal 属性，以便在调⽤ abort() ⽅法时取消请求。',
        path: '/js7'
      },
      {
        id:'j8',
        title: 'JS 中怎么阻⽌事件冒泡和事件默认⾏为？',
        content: '在 JavaScript 中，我们可以通过 AbortController 和 AbortSignal 来中⽌⽹络请求。使⽤ AbortController 创建⼀个新的 AbortController 对象，然后使⽤它的 abort() ⽅法来中⽌请求。在创建请求时，将 AbortController.signal 分配给请求的 signal 属性，以便在调⽤ abort() ⽅法时取消请求。',
        path: '/js8'
      },
      {
        id:'j9',
        title: '什么是防抖和节流？如何⽤ JS 编码实现？',
        content: '在 JavaScript 中，我们可以通过 AbortController 和 AbortSignal 来中⽌⽹络请求。使⽤ AbortController 创建⼀个新的 AbortController 对象，然后使⽤它的 abort() ⽅法来中⽌请求。在创建请求时，将 AbortController.signal 分配给请求的 signal 属性，以便在调⽤ abort() ⽅法时取消请求。',
        path: '/js9'
      },
      {
        id:'j10',
        title: '前端有哪些实现跨⻚⾯通信的⽅法？',
        content: '在 JavaScript 中，我们可以通过 AbortController 和 AbortSignal 来中⽌⽹络请求。使⽤ AbortController 创建⼀个新的 AbortController 对象，然后使⽤它的 abort() ⽅法来中⽌请求。在创建请求时，将 AbortController.signal 分配给请求的 signal 属性，以便在调⽤ abort() ⽅法时取消请求。',
        path: '/js10'
      },
    ]
  },
  getters: {
  },
  mutations: {
    addcount(state, num) {
      state.count += num
    }
  },
  actions: {
  },
  modules: {
  }
})
