import Vue from "vue";

Vue.directive('pwd-check',{
  bind:function(el,binding,vNode){
    el.oninput = (e) =>{
      let value = e.target.value;
      if(value.trim().length <= 0){
        vNode.context.status = '密码不能为空';
      }else if(value.trim().length < 6){
        vNode.context.status = '弱';
      }else if(value.trim().length > 6 && value.trim().length < 16){
        vNode.context.status = '中';
      }else{
        vNode.context.status = '强';
      }
    }
  }
})
