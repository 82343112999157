<template>
  <div class="home">
    <el-container>
      <el-header :height="cssData.elMianHeight">
        <Main></Main>
      </el-header>
      <el-footer>
        <Footer />
      </el-footer>
      <!-- <Dog /> -->
      <!-- <Ikun /> -->
    </el-container>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Main from "@/views/MainView.vue";
import Dog from "@/views/animation/CuteDog.vue";
import Ikun from "@/views/animation/IkunView.vue";
export default {
  name: "HomeView",
  components: {
    Footer,
    Main,
    // Dog,
    // Ikun,
  },
  data() {
    return {
      cssData: {
        elMianHeight: document.documentElement.clientHeight * 0.9 + "px",
      },
    };
  },
  mounted() {
    //设置el-main的高度
    // document.getElementById('el-main').style.height = this.cssData.elMianHeight;
    //设置el-footer的高度
    // document.getElementById('el-footer').style.height = this.cssData.elFooterHeight;
  },
};
//获取屏幕高度
// this.screenHeight = document.documentElement.clientHeight
</script>
<style scoped>
.el-header {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
}

.el-footer {
  background-color: #b1d1f0;
  color: #333;
  text-align: center;
  line-height: 60px;
}

#container {
  width: 30%;
  height: 100%;
  position: absolute;
  top: 20px;
  left: 0;
}
</style>
