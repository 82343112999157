<template>
  <div class="hobby">
    <el-button type="primary" icon="el-icon-back" class="back" @click="back" circle></el-button>
    <!-- <el-button type="primary" >主要按钮</el-button> -->
    <el-row>
      <el-col :span="5" v-for="item in imgs" :key="item.id">
        <el-skeleton style="width: 240px" :loading="loading" animated>
          <template slot="template">
            <el-skeleton-item
              variant="image"
              style="width: 240px; height: 240px;"
            />
            <div style="padding: 14px;">
              <el-skeleton-item variant="h3" style="width: 50%;" />
              <div
                style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;"
              >
                <el-skeleton-item variant="text" style="margin-right: 16px;" />
                <el-skeleton-item variant="text" style="width: 30%;" />
              </div>
            </div>
          </template>

        <el-card shadow="hover" :body-style="{ padding: '0px' }">
          <img :src="item.url" class="image">
          <div style="padding: 14px;">
            <span>{{item.content}}</span>
            <div class="bottom clearfix">
              <time class="time">{{ currentDate }}</time>
              <el-button type="primary" round size="mini" @click="openBigImg" :plain="true">查看大图</el-button>
              <!-- :plain="true"删除这个，取消消息提示  -->
            </div>
          </div>
        </el-card>
        </el-skeleton>
      </el-col>
    </el-row>
    <el-button type="primary" icon="el-icon-top" class="upToTop" @click="upToTop" circle></el-button>
</div>
</template>
<script>
export default {
  name: 'HobbyView',
  data() {
    return {
      loading: true,
      currentDate: new Date(),
      imgs:[
        {id:"1",content:"madala1",url:"http://feliks.icu/images/madala1.jpg"},
        {id:"2",content:"madala2",url:"http://feliks.icu/images/madala2.png"},
        {id:"3",content:"madara3",url:"http://feliks.icu/images/madala3.jpg"},
        {id:"4",content:"obito1",url:"http://feliks.icu/images/obito1.png"},
        {id:"5",content:"obito2",url:"http://feliks.icu/images/obito2.png"},
        {id:"6",content:"obito3",url:"http://feliks.icu/images/obito3.png"},
        {id:"1",content:"madala1",url:"http://feliks.icu/images/madala1.jpg"},
        {id:"2",content:"madala2",url:"http://feliks.icu/images/madala2.png"},
        {id:"3",content:"madara3",url:"http://feliks.icu/images/madala3.jpg"},
        {id:"4",content:"obito1",url:"http://feliks.icu/images/obito1.png"},
        {id:"5",content:"obito2",url:"http://feliks.icu/images/obito2.png"},
        {id:"6",content:"obito3",url:"http://feliks.icu/images/obito3.png"},
        {id:"1",content:"madala1",url:"http://feliks.icu/images/madala1.jpg"},
        {id:"2",content:"madala2",url:"http://feliks.icu/images/madala2.png"},
        {id:"3",content:"madara3",url:"http://feliks.icu/images/madala3.jpg"},
        {id:"4",content:"obito1",url:"http://feliks.icu/images/obito1.png"},
        {id:"5",content:"obito2",url:"http://feliks.icu/images/obito2.png"},
        {id:"6",content:"obito3",url:"http://feliks.icu/images/obito3.png"},
      ]
    };
  },
  mounted() {
    //判断页面滑倒底部
    window.onscroll = () => {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.open2();
      }
    };
    //定时器
    setInterval(() => {
      this.loading = false;
    }, 1000);
  },
  methods: {
    getImgs() {
      console.log(this.imgs);
    },
    back() {
      this.$router.push({ path: "/" });
    },
    upToTop() {
      //平滑滚动到顶部
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    //警告提示框
    open2() {
        this.$notify({
          title: '没有更多图片咯',
          message: '哈哈，无了',
          type: 'warning'
        });
    },
    //点击按钮查看大图
    openBigImg(){
      if(localStorage.getItem("name")!=="feliks"){
        this.$message("你不是本人，不给看");
      }else{
        this.$message.success("你是本人，可以看,但是我没做好哈哈哈");
      }
    }
  },
}
</script>
<style scoped>
.back{
  position: fixed;
  left: 10px;
  top: 10px;
}
.upToTop{
  position: fixed;
  right: 20px;
  bottom: 40px;
}
span{
  color: #409eff;
}
.time {
  font-size: 13px;
  color: #999;
}
img{
  height: 200px;
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}
.el-button span{
  color: #409eff;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}
.el-row{
  margin-left:50px;
}
.el-col{
  margin: 20px;
}
</style>
