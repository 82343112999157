<template>
  <div id="app">
    <!-- <HomeView/> -->
    <router-view></router-view>
</div>
</template>
<script>
// import HomeView from './views/HomeView.vue'
export default {
  name: 'App',
  components: {
    // HomeView
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  margin: 0;
}

/*p,h1,h4,span{
  color:#fff
}*/

.them-bg-color {
  background-color: rgb(65, 184, 131);
}

.them-txt-color {
  color: rgb(53, 73, 94);
}
.w-10{
  width:10%;
}
.w-20{
  width:20%;
}
</style>
