<template>
  <div class="personalInformation">
    <el-container>
      <el-aside width="200px" height="650px">
        <img src="https://www.felikschen.xyz/img/me.jpg" alt="我裂开了">
        <ul>
          <li>
            <router-link to="/">首页</router-link>
          </li>
          <li>
            <router-link to="/personal/ziliao">资料</router-link>
          </li>
          <li>
            <router-link to="/personal/skill">技能</router-link>
          </li>
          <li>
            <router-link to="/personal/production">作品</router-link>
          </li>
          <li>
            <router-link to="/personal/experience">经历</router-link>
          </li>
        </ul>
      </el-aside>
      <el-container>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
    <!-- <LeftPart/> -->
  </div>
</template>
<script>
  // import LeftPart from '@/views/self/LeftPart.vue'
  export default {
    name: 'PersonalInformation',
    // components: {
    //   LeftPart
    // },
    data() {
      return {
      }
    },
  }
</script>
<style lang="less">
  .personalInformation {
    height: 700px;
  }

  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;

    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }

    ul {
      list-style: none;
      margin-left: -30px;

      li {
        line-height: 60px;
        font-size: 16px;

        &:hover {
          color: #fff;
          background-color: #1F2D3D;
          cursor: pointer;
          transition: .5s;

          a {
            color: #fff;
          }
        }

        a {
          display: block;
          width: 100%;
          height: 100%;
          text-decoration: none;
        }
      }
    }
  }

  .el-main {
    background-color: #E9EEF3;
    color: #333;
    padding: 0;
    background: url("https://www.felikschen.xyz/Personal%20profile/static/img/bg-1.png");
    background-size:contain;
    /*text-align: center;
    line-height: 650px;*/
  }
</style>