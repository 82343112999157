import Vue from 'vue'
import ElementUI from 'element-ui'
// import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/axios'
//全局注册icon组件
import VIcon from './components/v-icon/index.vue'

Vue.component('v-icon', VIcon)

// Vue.prototype.$axios = axios;
Vue.config.productionTip = false
//应用element-ui
Vue.use(ElementUI).use(router)

//自定义工具类
import './utils/drag.js' //拖拽指令
import './utils/pwdCheck.js' //密码强度指令
import './utils/lazy.js' //图片懒加载指令
import './utils/scroll-load.js' //滚动加载指令


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
