import Vue from "vue";

//图片懒加载指令
Vue.directive("lazy", {
  bind: function (el, binding) {
    var observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          //当前图片出现在视口中，替换src
          el.src = binding.value;
          //停止监听
          observer.unobserve(el);
        }
      },
      { threshold: 0.01 }
    );
    //开始监听
    observer.observe(el);
  },
});
