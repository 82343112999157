import { render, staticRenderFns } from "./HobbyView.vue?vue&type=template&id=573befb0&scoped=true"
import script from "./HobbyView.vue?vue&type=script&lang=js"
export * from "./HobbyView.vue?vue&type=script&lang=js"
import style0 from "./HobbyView.vue?vue&type=style&index=0&id=573befb0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "573befb0",
  null
  
)

export default component.exports