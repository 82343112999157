<template>
  <div class="about">
    <h1>PC端作品</h1>
    <video width="640" height="480" controls>
      <source src="@/assets/video/pc.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <h1>移动端作品</h1>
    <video width="320" height="480" controls>
      <source src="@/assets/video/mobile_1.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <video width="320" height="480" controls>
      <source src="@/assets/video/mobile_2.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>

  </div>
</template>
<script>
// import OpenAI from "openai";
export default {
  name: 'ProductionView',
  data() {
    return {
      inner: '',
      speech: '',
      outer: '',
      drawIpt: '',
      imgUrl: '',
      chat_loading: false,
      loading: false,
      API_KEY: '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'
    }
  },
  mounted() {
    // var that = this;
  },
  methods: {
    async getResult() {
      this.chat_loading = true;
      const openai = new OpenAI({ apiKey: this.API_KEY, dangerouslyAllowBrowser: true });
      const completion = await openai.chat.completions.create({
        messages: [{ role: "system", content: `${this.inner}` }],
        model: "gpt-3.5-turbo",
      });
      this.outer = completion.choices[0].message.content;
      this.chat_loading = false;
    },

    async speak() {
      const openai = new OpenAI({ apiKey: this.API_KEY, dangerouslyAllowBrowser: true });
      // const speechFile = "speech.mp3";
      try {
        const mp3 = await openai.audio.speech.create({
          model: "tts-1",
          voice: "alloy",
          input: this.speech,
        });
        const arrayBuffer = await mp3.arrayBuffer();
        const blob = new Blob([arrayBuffer], { type: "audio/mp3" });

        // 使用 URL.createObjectURL 创建一个临时的音频链接
        const audioURL = URL.createObjectURL(blob);

        // 创建一个音频元素并设置其源为上面创建的链接
        const audioElement = new Audio(audioURL);

        // 在这里你可以将音频元素添加到页面上，或者执行其他操作
        audioElement.play();


        console.log("Speech created successfully!");
        //保存文件到assets目录
        const fileName = this.speech.substring(0, 3) + ".mp3";
        //截取this.speech前10个字符作为文件名

        const fileReader = new FileReader();
        fileReader.onload = function () {
          const arrayBuffer = this.result;
          const blob = new Blob([arrayBuffer], { type: "audio/mp3" });
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.click();

        };

      } catch (e) {
        console.log('error', e)
      }
    },

    async draw() {
      var that = this;
      this.loading = true;

      const openai = new OpenAI({ apiKey: this.API_KEY, dangerouslyAllowBrowser: true });
      async function main() {
        const image = await openai.images.generate({ model: "dall-e-3", prompt: that.drawIpt });

        console.log(image.data);
        that.imgUrl = image.data[0].url
        that.loading = false;
      }
      main();
    },

    async uploadFile(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('purpose', 'fine-tune');

      fetch('https://api.openai.com/v1/files', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.API_KEY}`
        },
        body: formData
      })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error(error));
    }
  }
}
</script>

<style scoped>
.chat,
.speech,
.draw {
  width: 80vw;
  margin: 10px auto;
}

.el-input {
  width: 60vw;
}

.img_container {
  width: 1024px;
  height: 1024px;
}

.chat_outer{
  width: 80vw;
  margin: 10px auto;
  height: 200px;
  overflow: auto;
}

.text_test {
  font-size: 16px;
}
</style>
