<template>
  <div id="container"></div>
</template>

<script>
import * as THREE from 'three';
import { Stats } from 'three/examples/jsm/libs/stats.module.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default {
  mounted() {
    var container, stats, controls, camera, scene, renderer, light;

    init();
    animate();
    function init() {
      container = document.getElementById('container');
      document.body.appendChild(container);//添加到body中
      camera = new THREE.PerspectiveCamera(755, window.innerWidth / window.innerHeight, .2, 100);
      camera.position.set(0, 1.6, 5);//相机位置
      scene = new THREE.Scene();//场景
      scene.background = new THREE.Color(0xdadada);//背景色
      scene.fog = new THREE.Fog(0xa0a0a0, 200, 1000);//雾化效果
      light = new THREE.HemisphereLight(0xffffff, 1);//环境光
      light.position.set(0, 100, 0);//光源位置
      scene.add(light);//环境光
      light = new THREE.DirectionalLight(0xffffff);//平行光
      light.position.set(0, 10, 20);//光源位置
      light.castShadow = true;//开启阴影
      scene.add(light);//平行光
      // var grid = new THREE.GridHelper(5, 20, 0x000000, 0x000000);
      // grid.material.opacity = 0.1;
      // grid.material.transparent = true;
      // scene.add(grid); //网格线

      var groundGeometry = new THREE.PlaneGeometry(10, 10, 1, 1);
      var groundMaterial = new THREE.MeshLambertMaterial({ color: 0xcccccc });
      var ground = new THREE.Mesh(groundGeometry, groundMaterial);
      ground.rotation.x = -Math.PI / 2;  // 将地面平面翻转至水平面
      ground.receiveShadow = true;  // 设置地面平面接受阴影
      //地面颜色
      ground.material.color.setHSL(0, 0, 0.60);
      // ground.position.y = -0.1;  // 将地面平面向下移动
      scene.add(ground);

      // model
      var rotationSpeed = 0.005;
      var loader = new FBXLoader();
      loader.load('assets/123.fbx', function (object) {
        undefined
        object.traverse(function (child) {
          undefined
          if (child.isMesh) {
            undefined
            child.castShadow = true;
            child.receiveShadow = true;
          }
        });
        object.castShadow = true;//开启阴影
        camera.position.set(1, 0.5, 2);//相机位置
        camera.lookAt(0, 0.5, 0);//相机朝向
        //旋转动画
        function animate() {
          requestAnimationFrame(animate);
          object.rotation.y += rotationSpeed;
          renderer.render(scene, camera);
        }
        animate();
        // object.rotation.x = -Math.PI / 3;//初始角度
        object.position.set(0, 0, 0);
        console.log(object.position);
        object.scale.set(.02, .02, .02);
        scene.add(object);
      });

      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth / 2, window.innerHeight / 2);
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      container.appendChild(renderer.domElement);
      controls = new OrbitControls(camera, renderer.domElement);
      controls.target.set(0, 0.5, 0);

      controls.update();
      window.addEventListener('resize', onWindowResize, false);

      // stats 左上角的帧率显示
      // stats = new Stats();
      // container.appendChild(stats.dom);
    }


    function onWindowResize() {
      undefined
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth / 2.5, window.innerHeight / 2.5);
    }

    //

    function animate() {
      undefined
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
      // stats.update();
    }

    //找到canvas元素
    var canvas = document.querySelector('canvas');
    canvas.style.border = '1px solid #000';
    canvas.style.margin = '0 auto';
  },
};
</script>

<style>
#container {
  width: 100%;
  height: 100%;
  position: absolute;
}

.btn {
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
</style>
