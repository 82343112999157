<template>
  <div class="main" ref="vantaRef">
    <img class="avt" :src="avatarsSrc" alt="" />
    <h1>{{ $store.state.username }}</h1>
    <p @click="add()">{{ username + sex + age }}</p>
    <p>{{ motto }}</p>
    <hr />
    <p>{{ socialMsg }}<br /><b>-「Feliks」</b></p>
    <MainList />
  </div>
</template>
<script>
import axios from "axios";
import MainList from "./MainList.vue";
import * as THREE from "three";
// import BIRDS from 'vanta/src/vanta.birds'
import WAVES from "vanta/src/vanta.waves";
import { mapState } from "vuex";
export default {
  name: "MainView",
  components: { MainList },
  data() {
    return {
      avatarsSrc: "http://47.109.109.141/avatar.jpg",
      name: "Feliks Chen",
      motto: "My Life is Nothing But A Comedy",
      socialMsg: "",
      //检测变化刷新---未完成
      // height: document.body.clientHeight - 67,
      // timer: false
    };
  },
  computed: {
    //引入VueX中的state
    ...mapState(["username", "sex", "age", "count"]),
  },
  methods: {
    add() {
      this.$store.commit("addcount", 1);
    },
  },
  mounted() {
    let SH = document.documentElement.clientHeight * 0.9 + "px";
    let mainbox = document.querySelector(".main");
    mainbox.style.height = SH;
    //获取社会语录
    axios
      .get("https://api.uixsj.cn/hitokoto/get?type=social")
      .then((response) => {
        this.socialMsg = response.data;
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    //vanta
    this.vantaEffect = WAVES({
      el: this.$refs.vantaRef,
      THREE: THREE,
    });
    //自定义属性
    this.vantaEffect.setOptions({
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      skyColor: 0x91cde3,
      cloudColor: 0xc9c9d9,
      cloudShadowColor: 0x174b7d,
      sunColor: 0xe37f05,
      speed: 1.5,
    });
    //监测变化刷新---未完成
    // const that = this
    // window.onresize = () => {
    // 	return (() => {
    // 		window.screenHeight = document.body.clientHeight
    // 		that.height = window.screenHeight - 67;
    // 	})()
    // }
  },
  //监测变化刷新---未完成
  // watch: {
  // 	height(val) {
  // 		// 为了避免频繁触发resize函数导致页面卡顿，使用定时器
  // 		if (!this.timer) {
  // 			// 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
  // 			this.screenHeight = val
  // 			this.timer = true
  // 			let that = this
  //       console.log(val);
  // 			setTimeout(function() {
  // 				that.timer = false
  // 			}, 400)
  // 		}
  // 	}
  // },
  //销毁vanta
  beforeDestroy() {
    if (this.vantaEffect) {
      this.vantaEffect.destroy();
    }
  },
};
</script>
<style scoped>
p,
h1,
h4,
span {
  color: #fff;
}

.avt {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 100px auto 0;
}

hr {
  width: 30%;
  color: #ccc;
}

p {
  font-size: 16px;
}
</style>
